import React, { useEffect, useState } from "react";
import { getNewestReviews } from "../api";
import "../styles/components/NewestReviews.css";

const NewestReviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getNewestReviews()
      .then((response) => setReviews(response.data))
      .catch((error) => console.error("Fehler beim Laden der Bewertungen:", error));
  }, []);

  return (
    <div className="newest-reviews">
      <h2>Neueste Bewertungen</h2>
      <div className="reviews-list">
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <h3>{review.business?.name || "Geschäft unbekannt"}</h3>
            <p>"{review.comment}"</p>
            <div className="review-meta">
              <span>⭐ {review.rating}</span>
              <span>{new Date(review.date).toLocaleDateString()}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewestReviews;
