import React from "react";
import "../styles/components/Hero.css";
import SearchBar from "./SearchBar"; // Neue SearchBar-Komponente

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Finde die besten lokalen Geschäfte in deiner Nähe</h1>
        
        {/* Neue SearchBar-Komponente */}
        <SearchBar />

        <div className="cta-buttons">
          <button>Jetzt bewerten</button>
          <button>Geschäfte durchsuchen</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
