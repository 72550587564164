import React, { useState, useEffect } from "react";
import { postReview, getBusinesses } from "../api";
import "../styles/components/ReviewForm.css";

const ReviewForm = () => {
  const [formData, setFormData] = useState({
    user: "64c9e8f8c3b3bc4a5c001234", // Beispiel-User-ID
    business: "",
    rating: 0,
    comment: "",
  });
  const [businesses, setBusinesses] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await getBusinesses();
        console.log("Businesses:", response.data); // Debugging
        setBusinesses(response.data);
      } catch (error) {
        console.error("Fehler beim Laden der Geschäfte:", error);
      }
    };
    fetchBusinesses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.user || !formData.business || !formData.rating) {
      setMessage("Bitte füllen Sie alle erforderlichen Felder aus.");
      return;
    }

    try {
      await postReview(formData);
      setMessage("Bewertung erfolgreich gespeichert!");
      setFormData({ ...formData, business: "", rating: 0, comment: "" });
    } catch (error) {
      console.error("Fehler beim Speichern der Bewertung:", error);
      setMessage("Fehler beim Speichern der Bewertung.");
    }
  };

  return (
    <div className="review-form">
      <h2>Bewertung abgeben</h2>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Geschäft:
          <select
            name="business"
            value={formData.business}
            onChange={handleChange}
            required
          >
            <option value="">Geschäft auswählen</option>
            {businesses.map((business) => (
              <option key={business._id} value={business._id}>
                {business.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Sternebewertung:
          <input
            type="number"
            name="rating"
            value={formData.rating}
            onChange={handleChange}
            min="1"
            max="5"
            required
          />
        </label>
        <label>
          Kommentar:
          <textarea
            name="comment"
            value={formData.comment}
            onChange={handleChange}
          ></textarea>
        </label>
        <button type="submit">Bewertung speichern</button>
      </form>
    </div>
  );
};

export default ReviewForm;
