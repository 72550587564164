import React from "react";
import { Categories } from "../components/Categories";
import "../styles/pages/AllCategories.css";

const AllCategories = () => {
  return (
    <div>
      <Categories /> {/* Zeigt alle Kategorien */}
    </div>
  );
};

export default AllCategories;
