import React, { useState, useEffect } from "react";
import { getBusinesses } from "../api";
import "../styles/components/SearchBar.css";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleSearch = async (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await getBusinesses();
      const filteredResults = response.data.filter((business) =>
        business.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults);
    } catch (error) {
      console.error("Fehler beim Abrufen der Suchergebnisse:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSearch(searchQuery);
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handleResultClick = (business) => {
    console.log(`Selected business: ${business.name}`);
    setSearchQuery(""); // Reset Suche
    setIsFocused(false); // Fokus zurücksetzen
  };

  return (
    <div className="search-container">
      {/* Overlay */}
      {isFocused && <div className="search-overlay" onClick={() => setIsFocused(false)}></div>}

      <div className="search-bar">
        <input
          type="text"
          placeholder="Wonach suchst du?"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsFocused(true)} // Aktiviert den Fokus-State
        />
        {isFocused && searchQuery && (
          <div className="search-results">
            {isLoading ? (
              <div className="loader">Lädt...</div>
            ) : searchResults.length > 0 ? (
              <ul>
                {searchResults.map((business) => (
                  <li key={business._id} onClick={() => handleResultClick(business)}>
                    <div className="result-item">
                      <div className="result-left">
                        {/* Placeholder für Logo */}
                        <div className="result-logo">
                          <img
                            src="https://via.placeholder.com/50"
                            alt={`${business.name} Logo`}
                          />
                        </div>
                        <div className="result-info">
                          <p className="result-name">{business.name}</p>
                          <p className="result-details">
                            {business.city || "Unbekannt"}, {business.zip || "00000"} <br />
                            <a
                              href={business.website || "#"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {business.website || "Keine Webseite"}
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="result-rating">
                        {/* Placeholder für Bewertung */}
                        <span className="rating-badge">
                          {business.rating ? business.rating.toFixed(1) : "n/a"}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Keine Ergebnisse gefunden</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
